
let HeartCoordinates = [
    {x:0, y:6, gpId:1},
    {x:2, y:8, gpId:1},
    {x:5, y:8, gpId:1},
    {x:7, y:6, gpId:1},
    {x:7.5, y:3.5, gpId:1},
    {x:7, y:1, gpId:1},
    {x:4, y:-2, gpId:1},
    {x:2, y:-4, gpId:1},
    {x:0, y:4, gpId:1},
    {x:1, y:2, gpId:1},
    {x:3, y:0, gpId:1},
    {x:0, y:0, gpId:1},
    {x:0, y:-2, gpId:1},
    {x:0, y:-4, gpId:1},
    {x:0, y:-6, gpId:1},
    {x:1, y:-3, gpId:1},
    {x:3, y:-1, gpId:1},
    {x:3.5, y:7, gpId:1},
    {x:5, y:4, gpId:1},
    {x:2, y:4, gpId:1},
    {x:5, y:1, gpId:1},
    {x:3, y:2, gpId:1},
    
    
    {x:0, y:-6, gpId:2},
    {x:-2, y:-4, gpId:2},
    {x:-4, y:-2, gpId:2},
    {x:-7, y:1, gpId:2},
    {x:-7.5, y:3.5, gpId:2},
    {x:-7, y:6, gpId:2},
    {x:-5, y:8, gpId:2},
    {x:-2, y:8, gpId:2},
    {x:0, y:6, gpId:2},
    {x:0, y:4, gpId:2},
    {x:-1, y:2, gpId:2},
    {x:-3, y:0, gpId:2},
    {x:0, y:0, gpId:2},
    {x:0, y:-2, gpId:2},
    {x:0, y:-4, gpId:2},
    {x:-1, y:-3, gpId:2},
    {x:-3, y:-1, gpId:2},
    {x:-3.5, y:7, gpId:2},
    {x:-5, y:4, gpId:2},
    {x:-2, y:4, gpId:2},
    {x:-5, y:1, gpId:2},
    {x:-3, y:2, gpId:2},
    
];

export default HeartCoordinates;