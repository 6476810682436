const JsCoordinates = [
    /* J */
    {x:-4.5, y:8, gpId:10},
    {x:-4.5, y:6, gpId:10},
    {x:-3.5, y:6, gpId:10},
    /**/
    {x:-3.5, y:6, gpId:14},
    {x:-3.5, y:4, gpId:14},
    {x:-3.5, y:2, gpId:14},
    {x:-3.5, y:0, gpId:14},
    {x:-3.5, y:-2, gpId:14},
    {x:-3.5, y:-4, gpId:14},
    {x:-3.5, y:-5, gpId:14},
    {x:-3.5, y:-5, gpId:13},
    {x:-6, y:-5, gpId:13},
    {x:-6, y:-5, gpId:12},
    {x:-6, y:-3, gpId:12},
    {x:-6, y:-2, gpId:12},
    {x:-8, y:-2, gpId:12},
    {x:-8, y:-3, gpId:12},
    {x:-8, y:-5, gpId:12},
    {x:-8, y:-6, gpId:12},
    {x:-7, y:-7, gpId:12},
    {x:-7, y:-7, gpId:13},
    {x:-5, y:-7, gpId:13},
    {x:-2.5, y:-7, gpId:13},
    {x:-1.5, y:-6, gpId:13},
    {x:-1.5, y:-4, gpId:13},
    {x:-1.5, y:-4, gpId:14},
    {x:-1.5, y:-2, gpId:14},
    {x:-1.5, y:0, gpId:14},
    {x:-1.5, y:2, gpId:14},
    {x:-1.5, y:4, gpId:14},
    {x:-1.5, y:6, gpId:14},/**/
    {x:-1.5, y:6, gpId:10},
    {x:-1.5, y:8, gpId:10},
    {x:-2.5, y:8, gpId:10},
    
    /* S */
    {x:2, y:8, gpId:22},
    {x:2, y:8, gpId:21},
    {x:4, y:8, gpId:21},
    {x:6, y:8, gpId:21},
    {x:6, y:8, gpId:20},
    {x:7, y:8, gpId:20},
    {x:8, y:7, gpId:20},
    {x:8, y:5, gpId:20},
    {x:6, y:5, gpId:20},
    {x:6, y:6, gpId:20},
    {x:6, y:6, gpId:21},
    {x:3, y:6, gpId:21},
    {x:3, y:6, gpId:22},
    {x:3, y:4, gpId:22},
    {x:3, y:3, gpId:22},
    {x:3, y:1.5, gpId:22},
    {x:3, y:1.5, gpId:23},
    {x:5, y:1.5, gpId:23},
    {x:6, y:1.5, gpId:23},
    {x:7, y:1.5, gpId:23},
    {x:8, y:0.5, gpId:23},
    {x:8, y:0.5, gpId:24},
    {x:8, y:-1, gpId:24},
    {x:8, y:-3, gpId:24},
    {x:8, y:-5, gpId:24},
    {x:8, y:-6, gpId:24},
    {x:7, y:-7, gpId:24},
    {x:7, y:-7, gpId:25},
    {x:5, y:-7, gpId:25},
    {x:3, y:-7, gpId:25},
    {x:2, y:-7, gpId:25},
    {x:2, y:-7, gpId:26},
    {x:1, y:-6, gpId:26},
    {x:1, y:-4, gpId:26},
    {x:3, y:-4, gpId:26},
    {x:3, y:-5, gpId:26},
    {x:3, y:-5, gpId:25},
    {x:6, y:-5, gpId:25},
    {x:6, y:-5, gpId:24},
    {x:6, y:-4, gpId:24},
    {x:6, y:-2, gpId:24},
    {x:6, y:-0.5, gpId:24},
    {x:6, y:-0.5, gpId:23},
    {x:5, y:-0.5, gpId:23},
    {x:3, y:-0.5, gpId:23},
    {x:2, y:-0.5, gpId:23},
    {x:2, y:-0.5, gpId:22},
    {x:1, y:0.5, gpId:22},
    {x:1, y:2, gpId:22},
    {x:1, y:4, gpId:22},
    {x:1, y:6, gpId:22},
    {x:1, y:6, gpId:22},
    {x:1, y:7, gpId:22}
    
];

export default JsCoordinates;