const ICoordinates = [
    {x:-1, y:8, gpId:2},
    {x:-2, y:8, gpId:2},
    {x:-2, y:6, gpId:2},
    {x:-1, y:6, gpId:2},
    {x:-1, y:6, gpId:1},
    {x:-1, y:4, gpId:1},
    {x:-1, y:2, gpId:1},
    {x:-1, y:0, gpId:1},
    {x:-1, y:-2, gpId:1},
    {x:-1, y:-4, gpId:1},
    {x:-1, y:-5, gpId:1},
    {x:-1, y:-5, gpId:2},
    {x:-2, y:-5, gpId:2},
    {x:-2, y:-7, gpId:2},
    {x:-1, y:-7, gpId:2},
    {x:1, y:8, gpId:2},
    {x:2, y:8, gpId:2},
    {x:2, y:6, gpId:2},
    {x:1, y:6, gpId:2},
    {x:1, y:6, gpId:1},
    {x:1, y:4, gpId:1},
    {x:1, y:2, gpId:1},
    {x:1, y:0, gpId:1},
    {x:1, y:-2, gpId:1},
    {x:1, y:-4, gpId:1},
    {x:1, y:-5, gpId:1},
    {x:1, y:-5, gpId:2},
    {x:2, y:-5, gpId:2},
    {x:2, y:-7, gpId:2},
    {x:1, y:-7, gpId:2},
];


export default ICoordinates;